const pagesForSuperAdmin = [
    '/organisations',
    '/users',
    '/email-trigger',
    '/data-management/markets',
    '/data-management/stations',
];

const pagesForOrganisationalAdmin = [
    '/campaigns',
    '/avails',
    '/markets',
    '/users',
    '/programme',
    '/data-management/clients',
    '/data-management/markets',
    '/data-management/stations',
    '/data-management/demographics',
    '/bookings',
    '/contacts',
    '/trafficking/creatives',
    '/trafficking/instructions',
    '/orders',
];

const pagesForOrganisationalUser = [
    '/campaigns',
    '/avails',
    '/markets',
    '/programme',
    '/bookings',
    '/contacts',
    '/trafficking/creatives',
    '/trafficking/instructions',
    '/orders',
];

const commonPages = [
    '/profile',
    '/ows',
    '/maintenance',
    '/request-timeout',
    '/access-denied',
    '/maintenance',
    '/internal-server',
]

const allPages = new Set([...pagesForSuperAdmin, ...pagesForOrganisationalAdmin, ...pagesForOrganisationalUser, ...commonPages]);

const RouterService = {
    isAccessibleForSuperAdmin(pathname) {
        const accessiblePages = [...pagesForSuperAdmin, ...commonPages];
        return accessiblePages.includes(pathname) || (/\d/.test(pathname) && accessiblePages.some(path => pathname.includes(path)));
    },

    isAccessibleForOrganisationalAdmin(pathname) {
        const accessiblePages = [...pagesForOrganisationalAdmin, ...commonPages];
        return accessiblePages.includes(pathname) || (/\d/.test(pathname) && accessiblePages.some(path => pathname.includes(path)));
    },

    isAccessibleForOrganisationalUser(pathname) {
        const accessiblePages = [...pagesForOrganisationalUser, ...commonPages];
        return accessiblePages.includes(pathname) || (/\d/.test(pathname) && accessiblePages.some(path => pathname.includes(path)));
    },

    isValidUrl(pathname) {
        return [...allPages].includes(pathname) || [...allPages].some(path => pathname.includes(path));
    },
};

export default RouterService;